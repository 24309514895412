import Button from '@components/atoms/Button/Button';
import DisplayImage from '@components/atoms/DisplayImage/DisplayImage';
import Heading from '@components/atoms/Heading/Heading';
import Image from 'next/image';
import { MediaTextProps } from '@data/strapi/Common/Types';
import Overline from '@components/atoms/Overline/Overline';
import React from 'react';
import RichText from '@components/atoms/RichText/RichText';
import cn from 'classnames';
import styles from './MediaText.module.scss';

export default function MediaText({
  mediaPosition,
  withBackground,
  media,
  overline,
  heading,
  description,
  buttons,
  className,
}: MediaTextProps) {
  return (
    <div
      className={cn(
        styles.mediaText,
        (mediaPosition && styles[mediaPosition]) || {},
        withBackground && styles.withBackground,
        className
      )}
    >
      <div>
        <div className={styles.media}>
          {media && media?.image?.data?.attributes && (
            <DisplayImage
              src={media?.image?.data?.attributes.url}
              width={Number(media?.image?.data?.attributes.width)}
              height={Number(media?.image?.data?.attributes.height)}
              fill={media?.image?.data?.attributes.fill}
              alt={media?.image?.data?.attributes.alternativeText}
              useCdn={media?.image?.data.attributes.useCdn}
            />
          )}
        </div>
        <div className={styles.content}>
          {overline && <Overline {...overline} className="mb-2" />}
          {heading && <Heading {...heading} />}
          {description && (
            <RichText
              value={
                typeof description === 'string'
                  ? description
                  : (description as any).value
              }
              className="mt-4"
            />
          )}
          {buttons &&
            buttons.length > 0 &&
            buttons.map((button, index) => {
              return (
                <Button key={`button-${index}`} {...button} className="mt-4" />
              );
            })}
        </div>
      </div>
    </div>
  );
}
