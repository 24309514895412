import React, { CSSProperties, ReactEventHandler } from 'react';

import Image from 'next/image';

interface IDisplayImage {
  src?: string;
  alt?: string;
  width?: number;
  height?: number;
  fill?: boolean;
  caption?: string;
  className?: string;
  children?: React.ReactNode;
  useCdn?: boolean;
  style?: CSSProperties;
  onError?: ReactEventHandler<HTMLImageElement>;
}

const DisplayImage = ({
  src,
  alt,
  width,
  height,
  fill,
  useCdn = true,
  caption,
  className,
  style,
  onError,
  ...props
}: IDisplayImage) => {
  let imgSrc = '';

  if (src?.indexOf('data:image/') === 0) {
    imgSrc = src;
  } else {
    imgSrc = src
      ? src?.indexOf('http') >= 0
        ? src
        : src.indexOf('/assets') === 0
          ? src
          : `${useCdn ? process.env.NEXT_PUBLIC_IMAGE_URL : ''}${src}`
      : '';
  }

  return (
    <Image
      src={imgSrc || '/assets/common/placeholder.svg'}
      alt={alt ? alt : ''}
      width={width}
      height={height}
      fill={fill}
      className={className}
      style={style}
      onError={onError}
    />
  );
};

export default DisplayImage;
