import { OverlineProps } from '@data/strapi/Common/Types'
import React from 'react';
import cn from 'classnames'
import styles from './Overline.module.scss'

export default function Overline({
  value,
  icon,
  className,
}: OverlineProps) {
  const elClass = cn(
    styles.overline,
    "h6",
    className,
  );

  return (
    <div className={elClass}>
      {icon && <i className={icon}></i>}
      <span>{value}</span>
    </div>
  )
}
